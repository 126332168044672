@use "../config" as *;
.waf-component.waf-venue {
    z-index: unset;
    @extend %px-0;
    @extend %white-bg;
    @extend %relative;
    @extend %hidden;
    iframe {
        @extend %w-100;
    }
}
.pattern-bg {
    &::before,
    &::after {
        filter: invert(0.7);
    }
}
.waf-venue-showcase,
.waf-venue-profile,
.waf-venue-location {
    @extend %mb-0;
}
.waf-venue-showcase {
    @extend %px-3;
    .tabs {
        .tab-name {
            padding: 1rem;
            border: .1rem solid var(--club-secondary);
            border-radius: unset;
            @include bg(white);
            &:first-child {
                border-radius: var(--half-radius) 0 0 var(--half-radius);
            }
            &:last-child {
                border-radius: 0 var(--half-radius) var(--half-radius) 0;
            }
            .tab-text {
                color: var(--club-secondary);
            }
            &.active {
                @extend %club-secondary-bg;
                .tab-text {
                    @extend %white;
                }
            }
            &:hover {
                @extend %white-bg;
            }
        }
    }
    .tab-container-item {
        @extend %mb-3;
    }
    .waf-head {
        .head-wrap {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        .head-tab {
            padding-left: 0;
        }
        .title {
            @extend %font-17-pb;
        }
        .tabs {
            @extend %flex;
            @extend %mb-4;
        }
    }
    .img-box {
        border-radius: 0;
    }
    .content {
        @extend %club-secondary-bg;
        @extend %relative;
        @extend %hidden;
        @extend %mx-3;
        @extend %my-8;
        &::after,
        &::before {
            content: "";
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            opacity: .15;
            width: 26rem;
            height: 26rem;
            pointer-events: none;
            filter: invert(1);
            @include bgImg("svg/hexagon.svg");
        }
        &::before {
            top: -16rem;
            right: -14rem;
        }
        &:after {
            right: -13rem;
            bottom: -18rem;
        }
        &-wrapper {
            @extend %flex;
            @extend %flex-wrap;
        }
        &-item {
            @extend %p-5;
            @extend %relative;
            &:after {
                content: '';
                opacity: .4;
                @extend %white-bg;
            }
            &:nth-of-type(1) {
                flex: 0 0 100%;
                width: 100%;
                @include separator(80%, x, 0.1rem, white, 10);
            }
            &:nth-of-type(2) {
                flex: 0 0 50%;
                width: 50%;
                @include separator(50%, y, 0.1rem, white, 10);
                .content-item-wrap {
                    @extend %flex-column;
                }
            }
            &:nth-of-type(3) {
                flex: 0 0 50%;
                width: 50%;
                .content-item-wrap {
                    @extend %flex-column;
                }
            }
            .content-item-wrap {
                @extend %flex;
                // @extend %flex-n-c;
                @extend %gap-2;
            }
        }
        &-text {
            .text {
                line-height: 1.2;
                @extend %white;
                @extend %mb-0;
                &-sm {
                    @extend %font-10-pb;
                    @extend %uppercase;
                }
                &-md {
                    @extend %font-16-pb;
                    @extend %uppercase;
                }
                &-lg {
                    @extend %font-20-pb;
                }
            }
        }
    }
    .content-item-image {
        .image {
            width: 3rem;
            height: 3rem;
            @extend %white-bg;
            @extend %flex-c-c;
            @extend %rounded-radius;
            &.stadium:before {
                font-size: 1.5rem;
                @include icon(stadium);
            }
            &.home:before {
                @include icon(home);
                font-size: 1.5rem;
            }
            &.year:before {
                @include icon(flag);
                font-size: 1.5rem;
            }
        }
    }
}
.waf-venue-profile {
    .waf-head {
        .title {
            @extend %font-17-pb;
        }
    }
    .layout-wrapper {
        @extend %medium-grey-bg;
        @extend %p-3;
    }
    .profile-content {
        line-height: 2.8rem;
        @extend %font-16-pr;
        @extend %secondary-dark;
        @extend %mb-5;
    }
}
.waf-venue-location {
    @extend %pt-6;
    .waf-head {
        .title {
            @extend %font-17-pb;
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-venue-showcase {
        .content {
            &-item {
                &:nth-of-type(2) {
                    flex: 0 0 50%;
                    width: 50%;
                    .content-item-wrap {
                        flex-direction: row;
                    }
                }
                &:nth-of-type(3) {
                    flex: 0 0 50%;
                    width: 50%;
                    .content-item-wrap {
                        flex-direction: row;
                    }
                }
            }
        }
    }
}
@media (min-width: $desktop-min-width) {
    .waf-venue-showcase {
        .waf-head {
            .head-wrap {
                margin-bottom: 1.5rem;
                @include flex-config(flex, row, null, center);
                .title {
                    margin-bottom: 0;
                }
            }
            .tabs {
                margin-bottom: 0;
            }
            .title {
                font-size: 2rem;
            }
        }
        .content {
            margin-inline: 18rem;
            &::before {
                display: none;
            }
            &:after {
                @include position-combo(br);
                @include position-combo(x-center);
            }
            &-item {
                &:nth-of-type(1) {
                    flex: 0 0 45%;
                    width: 45%;
                    @include separator(40%, y, 0.1rem, white, 10);
                    &:after {
                        left: unset;
                    }
                }
                &:nth-of-type(2) {
                    flex: 0 0 25%;
                    width: 25%;
                    .content-item-wrap {
                        flex-direction: row;
                        word-break: break-all;
                    }
                }
                &:nth-of-type(3) {
                    flex: 0 0 30%;
                    width: 30%;
                    .content-item-wrap {
                        flex-direction: row;
                    }
                }
                .content-item-wrap {
                    align-items: center;
                }
            }
            &-text {
                .text {
                    &-md {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
    .waf-venue-profile {
        .waf-head {
            .title {
                font-size: 2rem;
            }
        }
        .layout-wrapper {
            max-width: 78rem;
        }
    }
    .waf-venue-location {
        .waf-head {
            .title {
                font-size: 2rem;
            }
        }
        .layout-wrapper {
            max-width: 78rem;
        }
    }
}